<a *ngIf="isExternalLink(link?.route); else internalLink"
   [href]="link?.route"
   target="_blank"
   rel="noopener noreferrer">
    <p class="link-on-hover">{{ link?.text }}</p>
</a>
<ng-template #internalLink>
    <a [routerLink]="[link?.route]" routerLinkActive="active">
        <p class="link-on-hover">{{ link?.text }}</p>
    </a>
</ng-template>