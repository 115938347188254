import {Component, OnInit} from '@angular/core';
import {Author, Blog} from "../../../../models/blog";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {AuthService} from "@auth0/auth0-angular";
import {ActivatedRoute, Router} from "@angular/router";
import {BlogService} from "../service/blog.service";
import {finalize, of, switchMap} from "rxjs";
import {Modal, Toast} from "tw-elements";
import {environment} from "../../../../environments/environment";
import {goToUrl} from "../../../../services/util/util";

@Component({
    selector: 'app-blog-manage',
    templateUrl: './blog-manage.component.html',
    styleUrl: './blog-manage.component.css'
})
export class BlogManageComponent implements OnInit {

    blogContent: string = "";

    blog!: Blog;

    isEditMode: boolean = false;

    toastMessage: string = '';

    isLoading: boolean = false;

    blogControl: UntypedFormGroup = new UntypedFormGroup({
        id: new UntypedFormControl(''),
        title: new UntypedFormControl(''),
        category: new UntypedFormControl(''),
        summary: new UntypedFormControl(''),
        content: new UntypedFormControl(''),
        mainImage: new UntypedFormControl(''),
        slug: new UntypedFormControl(''),
        keywords: new UntypedFormControl(''),
        authorName: new UntypedFormControl('Непокор'),
        authorImage: new UntypedFormControl('https://nepokor.mk/assets/logo/logo.png'),
        authorLink: new UntypedFormControl('https://www.instagram.com/nepokor.mk/'),
        publishDate: new UntypedFormControl(''),
        isPublished: new UntypedFormControl(''),
        productAdIds: new UntypedFormControl('97,608,607,107'),
        originalPrice: new UntypedFormControl(''),
        blogKeyword: new UntypedFormControl(''),
        openAiInstructions: new UntypedFormControl("Однесувај се како експерт за контент и СЕО и врати одговор форматиран како чист JSON без ```json. Создади блог пост во HTML формат за дадена тема и структуирај го како JSON со следниве полиња:\n" +
            "\n" +
            "- title: Наслов на блог постот.\n" +
            "- category: Категорија на постот.\n" +
            "- summary: Краток опис од 1-2 реченици за содржината на постот.\n" +
            "- content: Детален HTML текст кој вклучува вовед, секции со наслови (H2, H3), слики со релевантни линкови, и заклучок. Форматирај го текстот за читливост со параграфи, листи, и цитати каде е соодветно. Насловите да бидат со strong таг.\n" +
            "- slug: Уникатен краток наслов кој ќе се користи за URL.\n" +
            "- keywords: Задолжително листа на релевантни клучни зборови за СЕО со мали букви и кирилица и латиница без празни места туку одвоени со - и без заградите []" +
            "\n" +
            "Теми и насоки:\n" +
            "1. Насловот треба да биде привлечен и релевантен.\n" +
            "2. Секциите треба да бидат добро структурирани со вовед, три главни секции (корист, предизвици, иднина), и заклучок.\n" +
            "3. Вклучи релевантни слики со линкови.\n" +
            "4. Користи природен јазик и вклучи дадени клучни зборови низ текстот.\n" +
            "\n" +
            "SEO насоки:\n" +
            "1. Вклучи зададениот клучен збор во насловот, slug-от, воведот, и главните секции.\n" +
            "2. Користи LSI (Latent Semantic Indexing) клучни зборови и синоними низ текстот.\n" +
            "3. Вметни ALT текст за сликите со клучен збор или релевантни термини.\n" +
            "4. Оптимизирај за читливост: Кратки параграфи, листи, цитати и други формати.\n" +
            "5. Генерирај содржина со минимум 800 зборови за повисока рангираност на Google."
            + "ЗАДОЛЖИТЕЛНО ДА Е ВАЛИДЕН JSON")

    });

    public editorContent = '';

    constructor(public auth: AuthService,
                private route: ActivatedRoute,
                private blogService: BlogService,
                protected readonly router: Router) {
    }

    ngOnInit(): void {
        this.route.paramMap.pipe(
            switchMap(params => {
                const id = params.get('id');
                if (id) {
                    this.isEditMode = true;
                    return this.blogService.getBlogById(+id);
                } else {
                    this.isEditMode = false;
                    return of(null);
                }
            })
        ).subscribe(blog => {
            if (this.isEditMode && blog) {
                this.blog = blog;
                this.setBlogValues(blog);
                this.editorContent = blog.content || '';
            }
        });
    }

    get idControl(): UntypedFormControl {
        return this.blogControl.get('id') as UntypedFormControl;
    }

    get titleControl(): UntypedFormControl {
        return this.blogControl.get('title') as UntypedFormControl;
    }

    get categoryControl(): UntypedFormControl {
        return this.blogControl.get('category') as UntypedFormControl;
    }

    get summaryControl(): UntypedFormControl {
        return this.blogControl.get('summary') as UntypedFormControl;
    }

    get contentControl(): UntypedFormControl {
        return this.blogControl.get('content') as UntypedFormControl;
    }

    get mainImageControl(): UntypedFormControl {
        return this.blogControl.get('mainImage') as UntypedFormControl;
    }

    get slugControl(): UntypedFormControl {
        return this.blogControl.get('slug') as UntypedFormControl;
    }

    get keywordsControl(): UntypedFormControl {
        return this.blogControl.get('keywords') as UntypedFormControl;
    }

    get authorNameControl(): UntypedFormControl {
        return this.blogControl.get('authorName') as UntypedFormControl;
    }

    get authorImageControl(): UntypedFormControl {
        return this.blogControl.get('authorImage') as UntypedFormControl;
    }

    get authorLinkControl(): UntypedFormControl {
        return this.blogControl.get('authorLink') as UntypedFormControl;
    }

    get publishDateControl(): UntypedFormControl {
        return this.blogControl.get('publishDate') as UntypedFormControl;
    }

    get isPublishedControl(): UntypedFormControl {
        return this.blogControl.get('isPublished') as UntypedFormControl;
    }

    get productAdIdsControl(): UntypedFormControl {
        return this.blogControl.get('productAdIds') as UntypedFormControl;
    }

    get blogKeywordControl(): UntypedFormControl {
        return this.blogControl.get('blogKeyword') as UntypedFormControl;
    }

    get openAiInstructionsControl(): UntypedFormControl {
        return this.blogControl.get('openAiInstructions') as UntypedFormControl;
    }

    setBlogValues(blog: Blog) {
        this.blogControl.patchValue({
            title: blog.title,
            category: blog.category,
            summary: blog.summary,
            content: blog.content,
            mainImage: blog.mainImage,
            slug: blog.slug,
            keywords: blog.keywords,
            authorName: blog.author.name,
            authorImage: blog.author.image,
            authorLink: blog.author.link,
            publishDate: blog.publishDate,
            isPublished: blog.isPublished,
            productAdIds: blog.productAdIds,
        });

        this.editorContent = blog.content || '';
    }

    public onEditorDataChange(data: string): void {
        this.editorContent = data;
    }

    addBlog() {
        const toArray = (value: string | number[] | undefined): number[] => {
            if (typeof value === 'string') {
                return value
                    .split(',')
                    .map(item => Number(item.trim()))
                    .filter(item => !isNaN(item));
            }
            return Array.isArray(value) ? value : [];
        };

        const author: Author = {
            name: this.authorNameControl.value,
            image: this.authorImageControl.value,
            link: this.authorLinkControl.value
        }

        const newBlog: Blog = {
            id: this.idControl.value,
            title: this.titleControl.value,
            category: this.categoryControl.value,
            summary: this.summaryControl.value,
            content: this.editorContent,
            mainImage: this.mainImageControl.value,
            slug: this.slugControl.value ? this.slugControl.value : '',
            keywords: this.formatKeywords(this.keywordsControl.value),
            author: author,
            isPublished: true,
            productAdIds: toArray(this.productAdIdsControl.value)
        }

        console.log(newBlog);
        this.blogService.addBlog(newBlog).subscribe({
            next: () => {
                alert("Блогот е успешно додаден");
                this.blogControl.reset();
                goToUrl(this.router, "/blogs");
            },
            error: () => alert("Грешка при додавање на блогот"),
        });
    }

    updateBlog() {
        const toArray = (value: string | number[] | undefined): number[] => {
            if (typeof value === 'string') {
                return value
                    .split(',')
                    .map(item => Number(item.trim()))
                    .filter(item => !isNaN(item));
            }
            return Array.isArray(value) ? value : [];
        };

        const author: Author = {
            name: this.authorNameControl.value,
            image: this.authorImageControl.value,
            link: this.authorLinkControl.value
        }

        const blog: Blog = {
            id: this.blog.id,
            title: this.titleControl.value,
            category: this.categoryControl.value,
            summary: this.summaryControl.value,
            content: this.editorContent,
            mainImage: this.mainImageControl.value,
            slug: this.slugControl.value ? this.slugControl.value : '',
            keywords: this.formatKeywords(this.keywordsControl.value) || this.blog?.keywords,
            author: author,
            isPublished: true,
            productAdIds: toArray(this.productAdIdsControl.value)
        }

        this.blogService.updateBlog(blog).subscribe({
            next: () => {
                alert("Успешно ажурирање на податоци");
                goToUrl(this.router, "/blogs");
            },
            error: () => alert("Грешка при ажурирање на податоци"),
        });
    }

    deleteBlog() {
        this.blogService.deleteBlog(this.blog.id!).subscribe({
            next: () => {
                alert("Успешно избришан блог");
                goToUrl(this.router, "/blogs");
            },
            error: () => alert("Грешка при бришење на блогот"),
        });
    }

    openToast(message: string) {
        const toast = new Toast(document.getElementById("toast"));
        this.toastMessage = message;
        toast.show();
    }

    openConfirmationModal(): void {
        if (this.blogControl.status === 'VALID') {
            const confirmationModal = new Modal(document.getElementById("confirmationModal"));
            confirmationModal.show();
        } else {
            this.openToast('Пополнете ја формата');
        }
    }

    private formatKeywords(value: string | undefined): string[] | undefined {
        if (typeof value === 'string') {
            return value.split(',')
                .map(keyword => keyword.trim())
                .filter(keyword => keyword.length > 0);
        }
        return undefined;
    }

    generateBlogContent() {
        this.isLoading = true;
        this.blogService.generateBlogContent(this.blogKeywordControl.value, this.openAiInstructionsControl.value)
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe({
                next: (blog) => {
                    this.editorContent = blog.content;
                    this.titleControl.setValue(blog.title);
                    this.categoryControl.setValue(blog.category);
                    this.summaryControl.setValue(blog.summary);
                    this.contentControl.setValue(blog.content);
                    this.slugControl.setValue(blog.slug);
                    this.keywordsControl.setValue(blog.keywords);
                },
                error: (err) => {
                    console.error('Грешка во креирање на блогот:', err);
                    alert('Грешка во креирање на блогот. Обиди се повторно.');
                }
            });
    }

    protected readonly document = document;
    protected readonly environment = environment;
}