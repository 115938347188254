<ng-container>
    <app-info-banner [class.hidden]="!showInfoBanner"></app-info-banner>
    <div class="bg-black h-16 lg:h-24 grid items-center">
        <nav @slideWrapper
             [class]="showMenu ? 'hidden' : 'hidden lg:mx-20 lg:grid lg:grid-cols-9 justify-center -translate-x-8 text-white text-xl font-medium text-center z-[100]'">
            @for (link of links; track link) {
                <app-link [class]="link.class!" [ngClass]="($index !== 3)?'lg:pt-3': ''" @slideDown
                          [link]="link"></app-link>
            }
        </nav>
        <!--        <div [@slideIn]="showMenu ? 'visible' : 'hidden'"-->
        <!--             [class]="showMenu ? 'pt-20 pl-5 pb-3 grid grid-col-1 space-y-5 bg-black text-white z-10 text-xl font-medium text-left' : 'hidden'">-->
        <!--            @for (link of links; track link) {-->
        <!--                <app-link [@slideLeft]="showMenu ? 'visible' : 'hidden'"-->
        <!--                          [link]="link" (click)="closeMenu()"></app-link>-->
        <!--            }-->
        <!--        </div>-->
    </div>
    <div class="absolute top-3.5 lg:top-7 left-14 lg:left-4  lg:right-5 flex">
        <app-primary-button
                class="w-9 lg:w-10 button-on-hover"
                [class]="'relative justify-self-end'"
                [ngClass]="showInfoBanner? 'pt-10 lg:pt-12': ''"
                [icon]="'/assets/icons/volume.svg'"
                [tooltip]="'MUSIC'"
                (click)="toggleMusicPlayer()"
                data-te-sidenav-hidden="true"
                data-te-target="#sidenav"></app-primary-button>
    </div>
    <div class="absolute top-3.5 lg:top-7 right-3 lg:right-5 flex">
        <div class="flex"
             [ngClass]="showInfoBanner? 'pt-10 lg:pt-12': ''">
            <app-login class="z-[100]"></app-login>
            <div class="relative inline-flex w-fit">
                @if (this.cartService.retrieveItemsFromLocalStorage().length > 0 && !showMenu) {
                    <div class="absolute bottom-auto z-[1000] left-auto right-0 top-0 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 whitespace-nowrap rounded-full bg-red-600 px-2.5 py-1 text-center align-baseline text-xs leading-none text-white">
                        {{ this.cartService.retrieveItemsFromLocalStorage().length }}
                    </div>
                }
                <app-primary-button
                        class="w-9 lg:w-10 button-on-hover z-[100]"
                        [class]="'relative justify-self-end'"
                        [icon]="'/assets/icons/search.svg'"
                        [tooltip]="'SEARCH'"
                        (click)="toggleSearchOverview()"
                        data-te-sidenav-hidden="true"
                        data-te-target="#sidenav"></app-primary-button>
                <app-primary-button
                        class="w-9 lg:w-10 button-on-hover z-[100]"
                        [class]="'relative justify-self-end'"
                        [icon]="'/assets/icons/shopping-bag.svg'"
                        [tooltip]="'GO TO CART'"
                        (click)="goToShoppingCart()"
                        data-te-sidenav-hidden="true"
                        data-te-target="#sidenav"></app-primary-button>
            </div>
        </div>


        <!--TODO @rev: use ngx-translate-->
        <!--        <c-dropdown class="justify-self-end">-->
        <!--            <div cDropdownToggle split class="pt-3"></div>-->
        <!--            <ul cDropdownMenu>-->
        <!--                @for (language of languages; track language) {-->
        <!--                    <li cDropdownItem>-->
        <!--&lt;!&ndash;                        <svg [cIcon]="languageIcons['cif' + language.text]" size="3xl"&ndash;&gt;-->
        <!--                             title="{{language.text.toUpperCase()}}"/>-->
        <!--                    </li>-->
        <!--                }-->
        <!--                }-->
        <!--            </ul>-->
        <!--        </c-dropdown>-->
    </div>
    <a class="z-50" href="/">
        <img [src]="'/assets/logo/logo.png'"
             [class]="showMenu ? 'hidden' : ' left-1/2 w-20 lg:w-auto absolute transform -translate-x-1/2'"
             [ngClass]="showInfoBanner? 'top-[3.8rem] lg:top-16': 'top-5 lg:top-4'"
             alt="logo"
             height="147"
             width="147"
             loading="eager"/>
    </a>
</ng-container>

<div id="menu">
    <button id="hamburger"
            class="absolute left-1.5 z-[150] flex flex-col gap-y-1.5 h-5 w-14 pr-4 pl-4 lg:hidden button-on-hover"
            [ngClass]="showInfoBanner? 'top-[3.85rem]':'top-6'"
            (click)="toggleVisibility()"
            data-te-sidenav-toggle-ref
            data-te-target="#sidenav"
            aria-controls="sidenav"
            aria-label="Toggle navigation menu">
        <div class="w-full h-0.5 rounded-sm bg-white" [class]="showMenu? 'hamburger-1' : ''"></div>
        <div class="w-full h-0.5 rounded-sm bg-white" [class]="showMenu? 'hamburger-2' : ''"></div>
        <div class="w-full h-0.5 rounded-sm bg-white" [class]="showMenu? 'hamburger-3' : ''"></div>
        <span class="sr-only">Toggle navigation menu</span>
    </button>

    <nav id="sidenav"
         class="fixed left-0 top-0 z-[100] h-screen -translate-x-full overflow-hidden bg-black shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] data-[te-sidenav-hidden='false']:translate-x-0"
         data-te-sidenav-init
         data-te-sidenav-hidden="true"
         data-te-sidenav-color="white"
         [attr.data-te-sidenav-width]="screenWidth">
        <ul class="relative m-0 list-none px-[0.2rem]" [@slideIn]="showMenu ? 'visible' : 'hidden'"
            [ngClass]="showInfoBanner? 'mt-[5.5rem]': 'mt-14'"
            data-te-sidenav-menu-ref>
            <li class="relative">
                <a [href]="links[0].route"
                   class="flex h-12 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-gray-300 outline-none transition duration-300 ease-linear hover:bg-white/10 hover:outline-none focus:bg-white/10 focus:outline-none active:bg-white/10 active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none"
                   data-te-sidenav-link-ref>
                    <app-link [@slideLeft]="showMenu ? 'visible' : 'hidden'"
                              [link]="links[0]" data-te-sidenav-toggle-ref
                              data-te-target="#sidenav" (click)="closeMenu()"></app-link>
                </a>
            </li>
            <li class="relative">
                <a class="flex h-12 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-gray-300 outline-none transition duration-300 ease-linear hover:bg-white/10 hover:outline-none focus:bg-white/10 focus:outline-none active:bg-white/10 active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none"
                   data-te-sidenav-link-ref>
                    <span [@slideLeft]="showMenu ? 'visible' : 'hidden'">Продавница</span>
                    <span [@slideLeft]="showMenu ? 'visible' : 'hidden'"
                          class="absolute right-0 ml-auto mr-[0.8rem] transition-transform duration-300 ease-linear motion-reduce:transition-none [&>svg]:text-gray-600 dark:[&>svg]:text-gray-300"
                          data-te-sidenav-rotate-icon-ref>
          <svg xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 20 20"
               fill="currentColor"
               class="h-5 w-5">
            <path fill-rule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clip-rule="evenodd"/>
          </svg>
        </span>
                </a>
                <ul class="!visible relative m-0 hidden list-none p-0 data-[te-collapse-show]:block "
                    data-te-sidenav-collapse-ref>
                    @for (link of shopLinks; track link) {
                        <li class="relative">
                            <a [href]="link.route">
                                <app-link
                                        class="flex h-6 cursor-pointer items-center truncate rounded-[5px] py-4 pl-[3.4rem] pr-6 text-[0.78rem] text-gray-300 outline-none transition duration-300 ease-linear hover:bg-white/10 hover:outline-none focus:bg-white/10 focus:outline-none active:bg-white/10 active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none"
                                        [link]="link"
                                        (click)="closeMenu()"
                                        data-te-sidenav-toggle-ref
                                        data-te-target="#sidenav"
                                        [@slideLeftFast]="showMenu ? 'visible' : 'hidden'"></app-link>
                            </a>
                        </li>
                    }
                </ul>
            </li>
            <li class="relative">
                <a [href]="links[2].route"
                   class="flex h-12 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-gray-300 outline-none transition duration-300 ease-linear hover:bg-white/10 hover:outline-none focus:bg-white/10 focus:outline-none active:bg-white/10 active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none"
                   data-te-sidenav-link-ref>
                    <app-link [@slideLeft]="showMenu ? 'visible' : 'hidden'"
                              [link]="links[2]"
                              (click)="closeMenu()"
                              data-te-sidenav-toggle-ref
                              data-te-target="#sidenav"></app-link>
                </a>
            </li>
                        <li class="relative">
                            <a [href]="links[3].route"
                               class="flex h-12 text-red-500 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] outline-none transition duration-300 ease-linear hover:bg-white/10 hover:outline-none focus:bg-white/10 focus:outline-none active:bg-white/10 active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none"
                               data-te-sidenav-link-ref>
                                <app-link [@slideLeft]="showMenu ? 'visible' : 'hidden'"
                                          [link]="links[3]"
                                          (click)="closeMenu()"
                                          data-te-sidenav-toggle-ref
                                          data-te-target="#sidenav"></app-link>
                            </a>
                        </li>
<!--            <li class="relative">-->
<!--                <a [href]="links[4].route"-->
<!--                   class="flex h-12 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-gray-300 outline-none transition duration-300 ease-linear hover:bg-white/10 hover:outline-none focus:bg-white/10 focus:outline-none active:bg-white/10 active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none"-->
<!--                   data-te-sidenav-link-ref>-->
<!--                    <app-link [@slideLeft]="showMenu ? 'visible' : 'hidden'"-->
<!--                              [link]="links[4]"-->
<!--                              (click)="closeMenu()"-->
<!--                              data-te-sidenav-toggle-ref-->
<!--                              data-te-target="#sidenav"></app-link>-->
<!--                </a>-->
<!--            </li>-->
            <li class="relative">
                <a [href]="links[5].route"
                   class="flex h-12 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-gray-300 outline-none transition duration-300 ease-linear hover:bg-white/10 hover:outline-none focus:bg-white/10 focus:outline-none active:bg-white/10 active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none"
                   data-te-sidenav-link-ref>
                    <app-link [@slideLeft]="showMenu ? 'visible' : 'hidden'"
                              [link]="links[5]"
                              (click)="closeMenu()"
                              data-te-sidenav-toggle-ref
                              data-te-target="#sidenav"></app-link>
                </a>
            </li>
            <li class="relative">
                <a [href]="links[6].route"
                   class="flex h-12 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-gray-300 outline-none transition duration-300 ease-linear hover:bg-white/10 hover:outline-none focus:bg-white/10 focus:outline-none active:bg-white/10 active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none"
                   data-te-sidenav-link-ref>
                    <app-link [@slideLeft]="showMenu ? 'visible' : 'hidden'"
                              [link]="links[6]"
                              (click)="closeMenu()"
                              data-te-sidenav-toggle-ref
                              data-te-target="#sidenav"></app-link>
                </a>
            </li>
            <li class="relative">
                <a [href]="links[7].route"
                   class="flex h-12 text-yellow-500 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] outline-none transition duration-300 ease-linear hover:bg-white/10 hover:outline-none focus:bg-white/10 focus:outline-none active:bg-white/10 active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none"
                   data-te-sidenav-link-ref>
                    <app-link [@slideLeft]="showMenu ? 'visible' : 'hidden'"
                              [link]="links[7]"
                              (click)="closeMenu()"
                              data-te-sidenav-toggle-ref
                              data-te-target="#sidenav"></app-link>
                </a>
            </li>
            <li class="relative">
                <a [href]="links[8].route"
                   class="flex h-12  cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-gray-300 outline-none transition duration-300 ease-linear hover:bg-white/10 hover:outline-none focus:bg-white/10 focus:outline-none active:bg-white/10 active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none"
                   data-te-sidenav-link-ref>
                    <app-link [@slideLeft]="showMenu ? 'visible' : 'hidden'"
                              [link]="links[8]"
                              (click)="closeMenu()"
                              data-te-sidenav-toggle-ref
                              data-te-target="#sidenav"></app-link>
                </a>
            </li>
        </ul>
    </nav>
</div>
@if (showSearchOverview) {
    <app-search-overview
            [showSearchOverview]="showSearchOverview"
            (showSearchOverviewChange)="handleSearchOverviewChange()">
    </app-search-overview>
}
@if (showMusicPlayer) {
    <app-music-player></app-music-player>
}
<!-- Sidenav -->