import {Component} from '@angular/core';

@Component({
    selector: 'app-social-links',
    templateUrl: './social-links.component.html',
    styleUrl: './social-links.component.css'
})
export class SocialLinksComponent {

}
