<div class="p-0 lg:p-16 pt-[8.5rem] lg:pt-52">
    @if (auth.user$ | async; as user) {
        @if (user.email === environment.admin) {
            @if (isLoading) {
                <div class="flex items-center justify-center pt-16 lg:pt-24">
                    <app-spinner></app-spinner>
                </div>
            } @else {
                <div class="grid lg:grid-cols-2 relative mb-5 md:mb-0">
                    <div class="pt-3 lg:pt-8 px-5 relative">
                        <div class="md:flex md:w-full items-center gap-5">
                            <div class="w-full lg:w-1/2">
                                <app-text-input
                                        [placeholder]="'Внеси клучен збор'"
                                        [control]="blogKeywordControl"></app-text-input>
                            </div>
                            <div class="w-full py-2">
                                <button type="button"
                                        class="text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-md text-sm px-5 py-2.5 text-center me-2"
                                        (click)="generateBlogContent()">
                                    Генерирај содржина
                                </button>
                            </div>
                        </div>
                        <div>
                            <app-text [value]="'Ид'"></app-text>
                            <app-text-input
                                    [placeholder]="'Внеси ид'"
                                    [control]="idControl"></app-text-input>
                        </div>
                        <div>
                            <app-text [value]="'Наслов'"></app-text>
                            <app-text-input
                                    [placeholder]="'Внеси наслов'"
                                    [control]="titleControl"></app-text-input>
                        </div>
                        <div>
                            <app-text [value]="'Категорија'"></app-text>
                            <app-text-input [placeholder]="'Внеси категорија'"
                                            [control]="categoryControl"
                                            [type]="'required'"></app-text-input>
                        </div>
                        <div>
                            <app-text [value]="'Краток опис'"></app-text>
                            <app-text-input [placeholder]="'Внеси краток опис'"
                                            [control]="summaryControl"
                                            [type]="'required'"></app-text-input>
                        </div>
                        <div>
                            <app-text [value]="'Насловна слика'"></app-text>
                            <app-text-input [placeholder]="'Внеси насловна слика'"
                                            [control]="mainImageControl"
                                            [type]="'required'"></app-text-input>
                        </div>
                        <div>
                            <app-text [value]="'Слаг'"></app-text>
                            <app-text-input [placeholder]="'Внеси слаг'"
                                            [control]="slugControl"
                                            [type]="'required'"></app-text-input>
                        </div>
                        <div>
                            <app-text [value]="'Клучни зборови'"></app-text>
                            <app-text-input [placeholder]="'Внеси клучни зборови'"
                                            [control]="keywordsControl"
                                            [type]="'required'"></app-text-input>
                        </div>
                        <div>
                            <app-text [value]="'Име на авторот'"></app-text>
                            <app-text-input [placeholder]="'Внеси име на авторот'"
                                            [control]="authorNameControl"
                                            [type]="'required'"></app-text-input>
                        </div>
                        <div>
                            <app-text [value]="'Слика на авторот'"></app-text>
                            <app-text-input [placeholder]="'Внеси слика на авторот'"
                                            [control]="authorImageControl"
                                            [type]="'required'"></app-text-input>
                        </div>
                        <div>
                            <app-text [value]="'Линк до авторот'"></app-text>
                            <app-text-input [placeholder]="'Внеси линк до авторот'"
                                            [control]="authorLinkControl"
                                            [type]="'required'"></app-text-input>
                        </div>
                        <div>
                            <app-text [value]="'Продукти за реклама'"></app-text>
                            <app-text-input [placeholder]="'Внеси ид на продукти за реклама'"
                                            [control]="productAdIdsControl"
                                            [type]="'required'"></app-text-input>
                        </div>
                    </div>

                    <div class="lg:pt-8 + px-5">
                        <div>
                            <app-text [value]="'Опен аи инструкции'"></app-text>
                            <app-text-input [placeholder]="'Внеси инструкции за генераторот'"
                                            [control]="openAiInstructionsControl"
                                            [type]="'required'"></app-text-input>
                        </div>
                        <div [innerHTML]="blogContent"></div>
                        <app-text [value]="'Содржина'"></app-text>
                        <app-rich-text [editorContent]="editorContent"
                                       [control]="contentControl"
                                       (editorDataChange)="onEditorDataChange($event)"></app-rich-text>
                    </div>
                </div>
                <div class="flex flex-shrink-0 flex-wrap items-center justify-center gap-x-3 p-5">
                    @if (isEditMode) {
                        <app-secondary-button
                                class="bg-red-600"
                                [value]="'Избриши го блогот'"
                                (click)="openConfirmationModal()"/>
                        <app-confirmation-modal (confirmationEvent)="deleteBlog()"></app-confirmation-modal>

                        <app-primary-button [value]="'Промени ги податоците'"
                                            (click)="updateBlog()"></app-primary-button>
                    } @else {
                        <app-primary-button [value]="'Додади нов блог'"
                                            (click)="addBlog()"></app-primary-button>
                    }
                </div>
            }
        }
    } @else {
        <p class="pt-56">unauthorised</p>
    }
</div>