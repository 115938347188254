import {Component} from '@angular/core';
import {Link} from "../../models/link";
import {environment} from "../../environments/environment";
import {IconLink} from "../../models/iconLink";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent {

    iconLinks: IconLink[] = [
        {
            icon: '/assets/icons/social-media/instagram_logo.svg',
            link: 'https://www.instagram.com/nepokor.mk/'
        },
        {
            icon: '/assets/icons/social-media/facebook_logo.svg',
            link: 'https://www.facebook.com/profile.php?id=100092531471685'
        },
        {
            icon: '/assets/icons/social-media/tiktok_logo.svg',
            link: 'https://www.tiktok.com/@nepokor.mk'
        }
    ]

    links: Link[] = [
        {
            title: "ПОЧЕТНА",
            text: "Почетна",
            route: environment.navigation.home,
        },
        {
            title: "ПРОДАВНИЦА",
            text: "Продавница",
            route: environment.navigation.shop,
        },
        {
            title: "БЛОГ",
            text: "Блог",
            route: environment.navigation.blog,
        },
        {
            title: "БРЕНД АМБАСАДОР",
            text: "Стани бренд амбасадор",
            route: environment.navigation.brandAmbassador,
            class: "text-red-500"
        },
        {
            title: "ЗА НАС",
            text: "За нас",
            route: environment.navigation.aboutUs,
        },
        {
            title: "КОНТАКТ",
            text: "Контакт",
            route: environment.navigation.contactUs,
        },
        {
            title: "ДОНИРАЈ",
            text: "Донирај",
            route: environment.paypalDonationLink,
            class: "text-yellow-500"
        },
    ];

    shopLinks: Link[] = [
        {
            text: "Маици",
            route: environment.navigation.shop + '/t_shirts',
        },
        {
            text: "Знамиња",
            route: environment.navigation.shop + '/flags',
        },
        {
            text: "Шишиња",
            route: environment.navigation.shop + '/bottles',
        },
        {
            text: "Дресови",
            route: environment.navigation.shop + '/jerseys',
        },
        {
            text: "Дуксери",
            route: environment.navigation.shop + '/hoodies',
        },
        {
            text: "Блузони",
            route: environment.navigation.shop + '/sweat_shirts',
        },
        {
            text: "Останато",
            route: environment.navigation.shop + '/other',
        },
        {
            text: "Попусти",
            route: environment.navigation.shop + '/on-discount',
        }
    ];

    openExternalLink(url: string): void {
        window.open(url, '_blank');
    }
}