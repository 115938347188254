import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SocialLinksComponent} from "./social-links.component";
import {AppRoutingModule} from "../../app-routing.module";
import {ImageModule} from "../../components/image/image.module";
import {SecondaryButtonComponent} from "../../components/button/secondary/secondary.button.component";
import {IconTextModule} from "../../components/icon-text/icon-text.module";
import {TextModule} from "../../components/text/text.module";
import {PrimaryButtonComponent} from "../../components/button/primary/primary.button.component";
import {FooterModule} from "../../components/footer/footer.module";


@NgModule({
    declarations: [SocialLinksComponent],
    imports: [
        CommonModule,
        AppRoutingModule,
        ImageModule,
        SecondaryButtonComponent,
        IconTextModule,
        TextModule,
        PrimaryButtonComponent,
        FooterModule
    ]
})
export class SocialLinksModule {
}
