<div class="pt-36 lg:pt-56 mx-auto min-h-screen">
    <app-image
            [src]="'/assets/logo/nepokor_text.svg'"
            class="w-44 lg:w-64 mx-auto pb-10"
    ></app-image>
    <app-text
            class="font-medium text-lg lg:text-2xl text-center w-4/5 lg:w-3/5 mx-auto"
            [value]="'Најголем избор на македонски патриотски производи на едно место.'"
    ></app-text>

    <div class="space-y-3 w-4/5 lg:w-3/5 mx-auto pt-20 lg:pt-14">

        <a href="https://www.facebook.com/profile.php?id=100092531471685"
           target="_blank"
           class="flex items-center justify-center bg-[#1877F2] text-white px-6 py-3 rounded-xl hover:bg-[#166FE5] transition-all duration-300 hover:shadow-lg hover:-translate-y-1">
            <i class="fab fa-facebook text-lg mr-2"></i>
            <span class="font-semibold">Придружи ни се на Facebook</span>
        </a>

        <a href="https://www.instagram.com/nepokor.mk"
           target="_blank"
           class="flex items-center justify-center bg-gradient-to-r from-[#833AB4] via-[#FD1D1D] to-[#FCB045] text-white px-6 py-3 rounded-xl hover:opacity-90 transition-all duration-300 hover:shadow-lg hover:-translate-y-1">
            <i class="fab fa-instagram text-lg mr-2"></i>
            <span class="font-semibold">Следи нè на Instagram</span>
        </a>

        <a href="https://www.youtube.com/@nepokor"
           target="_blank"
           class="flex items-center justify-center bg-[#FF0000] text-white px-6 py-3 rounded-xl hover:bg-[#CC0000] transition-all duration-300 hover:shadow-lg hover:-translate-y-1">
            <i class="fab fa-youtube text-lg mr-2"></i>
            <span class="font-semibold">YouTube</span>
        </a>

        <a href="https://www.tiktok.com/@nepokor.mk"
           target="_blank"
           class="flex items-center justify-center bg-black text-white px-6 py-3 rounded-xl hover:bg-gray-900 transition-all duration-300 hover:shadow-lg hover:-translate-y-1">
            <i class="fab fa-tiktok text-lg mr-2"></i>
            <span class="font-semibold">TikTok</span>
        </a>
    </div>
</div>
<app-footer></app-footer>